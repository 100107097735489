<template>
  <div class='disinfect'>
    <div class="action">
      <div>
        <span>位置选择：</span>
        <el-select clearable v-model="argumentsList.schoolId" placeholder="请选择" size="small" @change="schoolChange">
          <el-option v-for="item in cascadedlocation" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <span>日期选择：</span>
        <el-date-picker size="small" v-model="argumentsList.date" type="date" :picker-options="pickerOptions" align="right" :clearable="false" @change="dateChange">
        </el-date-picker>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" border size="small">
      <el-table-column prop="school" label="所属学校">
      </el-table-column>
      <el-table-column prop="deviceid" label="设备编号">
      </el-table-column>
      <el-table-column prop="location" label="设备位置">
      </el-table-column>
      <el-table-column prop="gmtclockin" label="消毒时间">
        <template slot-scope="{row}">
          <span :style="{color:row.gmtclockin === '未打卡'?'red':'green'}">
            {{row.gmtclockin === '未打卡'? row.gmtclockin : $moment(row.gmtclockin).format('YYYY-MM-DD HH:mm:ss')}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="realname" label="消毒人员">
        <template slot-scope="{row}">
          <span :style="{color:!row.realname?'red':'green'}">
            {{!row.realname?'无': row.realname}}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
  </div>
</template>

<script>
import { getSterilizeList } from '@/api'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      // 表格数据
      tableData: [],
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      location: '',
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      totalList: [],
      total: 0,
      argumentsList: {
        schoolId: '',
        date: this.$moment().format('YYYY-MM-DD')
      }
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['cascadedlocation'])
  },
  methods: {
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 获取消毒记录列表
    async init () {
      const res = await getSterilizeList(this.argumentsList)
      this.totalList = res
      this.total = res.length
      this.getList()
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    schoolChange () {
      this.init()
    },
    dateChange (val) {
      // console.log('val :>> ', val);
      this.argumentsList.date = this.$moment(val).format('YYYY-MM-DD')
      this.init()
    }
  }
};
</script>

<style scoped lang="scss">
.disinfect {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    > div {
      span {
        font-size: 16px;
        margin-left: 10px;
      }
      .el-select,
      .el-input {
        width: 300px;
      }
    }
  }
}
</style>
